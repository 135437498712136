import {BASE_URL, AuthHeaders, PublicApiUri, PublicApiKey, SpiderApiUri} from "./config";
import axios from "axios";
// import { orderBy, isEmpty } from 'lodash';
// import { getUser } from '../../utils/tokenManager';

export const fetchCompanyFeed = async (data) => {
    const response = await axios.post(
        `${BASE_URL}/companies/getcompanyfeed`,
        {
            id: data.id,
            limit: data.limit,
            offset: data.offset,
            activitiesOnly: data?.activitiesOnly,
            opportunitiesOnly: data?.opportunitiesOnly
        },
        {
            headers: {
                ...AuthHeaders()
            },
        }
    );
    return response.data;
};

export const fetchCompanyDetails = async (data) => {
    const response = await axios.get(
        `${BASE_URL}/companies/${data.id}`,
        {
            headers: {
                ...AuthHeaders()
            }
        }
    );
    return response.data;
};


export const fetchCompanyContacts = async (data) => {
    const response = await axios.get(
        `${BASE_URL}/companies/${data.companyId}/${data.type}?active=${data.isActive}&limit=${data.limit}&offset=${data.offset}&query=&sortBy=%2BlastName`,
        {
            headers: {
                ...AuthHeaders()
            }
        }
    );
    return response.data;
};

