import api from "../../api/index";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
    GET_COMPANIES,
    GET_SEARCH_DETAILS,
    getCompaniesSuccess,
    getSearchDetailsFailed,
    getSearchDetailsSuccess
} from "../actions/Search.actions";
import {searchCompanies} from "../../api/search.api";

export function* getSearchCompanyAndContactSaga(action) {
    try {
        const response = yield call(
            api.searchApi.searchCompanyAndContact,
            action.payload
        );
        yield put(getSearchDetailsSuccess(response));
    } catch (e) {
        yield put(getSearchDetailsFailed({}));

        console.log(e);
    }
}

export function* getSearchCompaniesSaga(action) {
    try {
        const response = yield call(
            api.searchApi.searchCompanies,
            action.payload
        );
        yield put(getCompaniesSuccess(response));
    } catch (e) {

        console.log(e);
    }
}

export function* watchSearchCompanyAndContact() {
    yield takeEvery(GET_SEARCH_DETAILS, getSearchCompanyAndContactSaga);
}

export function* watchSearchCompanies(action) {
    yield takeEvery(GET_COMPANIES, getSearchCompaniesSaga);
}

export default function* searchSagas() {
    yield all([
        watchSearchCompanyAndContact(),
        watchSearchCompanies()
    ]);
}