import initialState from '../initialState.json';
import {
    GET_SEARCH_DETAILS_SUCCESS,
    GET_SEARCH_DETAILS,
    REMOVE_SEARCH_DETAILS,
    GET_SEARCH_DETAILS_FAILED,
    GET_COMPANIES, GET_COMPANIES_SUCCESS
} from "../actions/Search.actions";


export default function (
    state = initialState,
    action
) {
    switch (action.type) {

           case REMOVE_SEARCH_DETAILS:
           case GET_SEARCH_DETAILS_FAILED: {
            return {
                ...state,
                isLoading: false,
                searchResults: {
                    ...state.searchResults,
                    contactsResults: [],
                    companiesResults: [],
                    elements: [],
                },
                showBackButton: false,
                contactSearchTerm: '',
                companySearchTerm: ''
            }
        }

        case GET_SEARCH_DETAILS: {
            let contactSearchTerm =  state?.contactSearchTerm || '';
            let companySearchTerm = state?.companySearchTerm || '';
            let companiesResults = state?.searchResults?.companiesResults || [];
            let contactsResults = state?.searchResults?.contactsResults || [];
            let companyOffset = state?.companyOffset;
            let contactsOffset = state?.contactsOffset;
            let companyPageNumber = state?.companyPageNumber;
            let contactPageNumber = state?.contactPageNumber;
            let companiesCount = state.companiesCount;
            let contactsCount = state.contactsCount;

            if(action.payload.type === 'companies') {
                companySearchTerm = action.payload.searchText || state?.companySearchTerm;
                if(action.payload.searchText !== state?.companySearchTerm) {
                    companiesResults = [];
                    companyOffset = 0;
                    companyPageNumber = 1;
                    companiesCount = 0;
                } else {
                    companyOffset =  action.payload.offset;
                    companyPageNumber = action.payload.pageNo;
                }
            }

            if(action.payload.type === 'contacts') {
                contactSearchTerm = action.payload.searchText || state?.contactSearchTerm;
                if(action.payload.searchText !== state?.contactSearchTerm) {
                    contactsResults = [];
                    contactsOffset = 0;
                    contactPageNumber = 1;
                    contactsCount = 0;
                } else {
                    contactsOffset =  action.payload.offset;
                    contactPageNumber = action.payload.pageNo;
                }
            }
            return {
                ...state,
                isLoading: true,
                companyOffset,
                contactsOffset,
                companyPageNumber,
                contactPageNumber,
                contactSearchTerm,
                companySearchTerm,
                contactsCount,
                companiesCount,
                searchResults: {
                    ...state.searchResults,
                    companiesResults,
                    contactsResults
                }
            }
        }



        case GET_SEARCH_DETAILS_SUCCESS: {
            let companiesResults = state?.searchResults?.companiesResults || [];
            let contactsResults = state?.searchResults?.contactsResults || [];
            let companiesCount = state?.searchResults?.totalCompanies;
            let contactsCount = state?.searchResults?.totalContacts;
            if(action?.payload?.elements && action?.payload?.totalCompanies) {
                companiesResults = companiesResults.concat(action.payload.elements);
                companiesCount = action?.payload?.totalCompanies;
            }
            if(action?.payload?.elements && action?.payload?.totalContacts) {
                contactsResults = contactsResults.concat(action.payload.elements);
                contactsCount = action?.payload?.totalContacts;
            }
            return {
                ...state,
                isLoading: false,
                searchResults: {
                 ...action.payload,
                    companiesResults,
                    contactsResults,
                    showBackButton: true,
                },
                companiesCount,
                contactsCount
            }
        }

         case GET_COMPANIES_SUCCESS: {
             debugger;
          return {
              ...state,
              companiesDataList: action.payload || [],
          }
        }




        default: {
            return {
                ...state
            }
        }
    }
}