import React from 'react';
import './EventFeedCard.css';
import {dateFormat, pills} from "../../../helpers/homepageHelper";
import {useSelector} from "react-redux";
import {SolIcon} from "@solstice/sol-react";
import {AvatarGroup, Avatar, Whisper, Tooltip} from 'rsuite';

import {MAX_AVATAR_COUNT} from "../../../helpers/homepageHelper";

const EventFeedCard = (props) => {
    let maxContactsAvatar = MAX_AVATAR_COUNT;
    const { feedDetails } = props;
    const userNameDetails = [];
    const user = useSelector((state) => state.userProfile);
    const isPublic = (jllActivity) => {
        return !!(isEditable(jllActivity, user) ||
            !jllActivity.isPrivate ||
            user?.isConfidential);


    };

    React.useMemo(() => {
        if(feedDetails?.contacts?.length > maxContactsAvatar) {
            feedDetails?.contacts?.forEach((contact, index) => {
              if(index > maxContactsAvatar) {
                  userNameDetails.push(contact.name);
              }
            })

        }
    }, [feedDetails?.contacts])

    const isEditable = (jllActivity, user) => {
        let editEnable = false;
        if ( user &&
           ( user?.isDealioAdmin ||
            user?.isAdministrator ||
            (jllActivity.owner && jllActivity.owner.id === user.id) ||
            (jllActivity.creatorId && jllActivity.creatorId === user.id) ||
            (jllActivity.contacts &&
                jllActivity.contacts.find(act => act.userId === user.id)))
        ) {
            editEnable = true;
        }
        return editEnable;
    };

    const getAdditionalAvatarContactsName = (details) => {
        let contactName = '';
        details?.contacts?.map((contact, index) => {
            if(index >= maxContactsAvatar) {
                contactName = contactName + contact.name + '<br/>';
            }
        });
        return contactName;
    }


    return (
        <div className='feedCardDetailsContainer'>
            <div className='feedCardHeader'>
                <div className='feedCardCompanyName'>
                    {feedDetails?.companyName}
                </div>
                <div className='feedCardDetails'>
                    {feedDetails?.isSubstantialActivity && <div className='feedCardPillsContainer' style={{
                        color: pills.substantial.color,
                        backgroundColor: pills.substantial.background,
                    }}>
                        <SolIcon icon={pills.substantial.icon} />
                        {pills.substantial.text}
                    </div>}
                    {(feedDetails?.dealioId && isPublic(feedDetails, user)) ? <div className='feedCardPillsContainer' style={{
                        color: pills.closeDeal.color,
                        backgroundColor: pills.closeDeal.background,
                    }}>
                        <SolIcon icon={pills.closeDeal.icon} />
                        {pills.closeDeal.text}
                    </div>: null}
                    <div className='feedCardDate'>
                        {dateFormat(feedDetails?.createdOn, 'll')}
                    </div>
                </div>
            </div>
            <div className='feedCardBrokerInfo'>
                <div className={'avatarContainer'}>
                    <Whisper placement={'bottomStart'} trigger="hover" speaker={<Tooltip placement={'bottom'}>{`Lead broker: ${feedDetails?.ownerDisplayName}`}</Tooltip>}> <div className={(feedDetails?.contacts?.length !== 0) ? 'avatarImage': 'avatarImageFixed'} key={0}>
                        <img style={{ height: 'auto'}} src={feedDetails?.ownerImageUrl} alt={feedDetails?.ownerDisplayName} /> </div></Whisper>
                    {feedDetails?.contacts?.filter((user, i) => i < maxContactsAvatar)
                        .map((user, index) => (
                            user.userImage ?<Whisper placement={'bottom'} trigger="hover" speaker={<Tooltip placement={'bottom'}>{user.name}</Tooltip>}> <div className={(index !== (feedDetails?.contacts?.length - 1)) ? 'avatarImage': 'avatarImageFixed'} key={user.id}>
                                <img style={{ height: 'auto'}} src={user.userImage} alt={user.name} />
                            </div> </Whisper>:  <Whisper placement={'bottom'} trigger="hover" speaker={<Tooltip placement={'bottom'}>{user.name}</Tooltip>}><div className={(index !== (maxContactsAvatar)) ? 'avatarImage': 'avatarImageFixed'}>
                                {user.name[0]}
                                {user.name.split(' ')[1].charAt(0)}
                            </div></Whisper>
                        ))}
                    {(feedDetails?.contacts.length > 4) && <Whisper placement={'bottom'} speaker={<Tooltip placement={'bottom'}><div dangerouslySetInnerHTML={{__html: getAdditionalAvatarContactsName(feedDetails)}} /></Tooltip>}> <div className={'avatarImageCount'} style={{ background: '#EAEFF1', color: '#637177' }}>
                        +{feedDetails?.contacts.length - maxContactsAvatar}
                    </div></Whisper>}

                </div>
                <div className='feedCardLocation'>{feedDetails?.city}</div>
            </div>
            <div className='feedCardSubject'>
                {feedDetails?.subject}
            </div>
            <div className='feedCardDescription'>
                {feedDetails?.description}
            </div>
        </div>
    )
}

export default EventFeedCard;